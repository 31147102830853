import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Timestamp } from '@sites/data-hmm/google-protobuf';
import { Subscription } from 'rxjs';

@Component({
  selector: 'property-form-date',
  templateUrl: './date.component.html',
})
export class DateComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  value = '';

  private subscriptions = new Subscription();

  ngOnInit() {
    if (this.formControl.value instanceof Timestamp) {
      this.setValue(this.formControl.value);
    }

    this.subscriptions.add(
      this.formControl.valueChanges.subscribe((value) => {
        if (value instanceof Timestamp) {
          this.setValue(value);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const date = new Date(event.target.value);
      const timestamp = Timestamp.fromDate(date);
      this.formControl.setValue(timestamp);
    }
  }

  setValue(timestamp: Timestamp) {
    const date = timestamp.toDate();
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const dateString = `${year}-${month}-${day}`;
    this.value = dateString;
  }
}
